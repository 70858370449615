<template>
  <section class="services">
    <router-link class="services__item" to="/art-advisory/virtual-placement">
      <img
        class="services__item-image"
        src="@/assets/images/services/virtual_placement_image.jpg"
        alt="art"
      />
      <h2 class="services__item-title text-title">Virtual Placement</h2>
      <p class="services__item-description text-description">
        See artwork in situ digitally before you make a decision.
      </p>
    </router-link>
    <router-link class="services__item" to="/art-advisory/commissions">
      <img
        class="services__item-image"
        src="@/assets/images/services/commissions_image.jpg"
        alt="art"
      />
      <h2 class="services__item-title text-title">Commissions</h2>
      <p class="services__item-description text-description">
        Work with artists to create site-specific works for public or private spaces.
      </p>
    </router-link>
    <router-link class="services__item" to="/art-advisory/presentation-assistance">
      <img
        class="services__item-image"
        src="@/assets/images/services/presentation_assistance_image.jpg"
        alt="art"
      />
      <h2 class="services__item-title text-title">Presentation Assistance</h2>
      <p class="services__item-description text-description">
        Exhibition Designs, online and in-print Artist Portfolios and digital Installation
        Proposals.
      </p>
    </router-link>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  head: {
    title: function () {
      return {
        inner: "Art Advisory browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Art Advisory browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Art Advisory browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Art Advisory browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Art Advisory browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Art Advisory browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Art Advisory browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Art Advisory browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Art Advisory browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Art Advisory",
        to: "/art-advisory",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>

<style lang="scss" scoped>
.services {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2.8rem;
  grid-row-gap: 6.5rem;
  padding: 21rem 10.5rem 11rem;
  @media screen and (max-width: $md) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 7.5rem;
    padding: 21rem 2rem 11rem;
  }
  &__item {
    display: block;
    cursor: pointer;
    text-decoration: none;
    @media screen and (max-width: $xxs) {
      width: 100%;
    }
    &-image {
      width: 100%;
      border-radius: 4px;
      @media screen and (max-width: $xxs) {
        width: 100%;
        height: 100vw;
      }
    }
    &-title {
      font-size: 4rem;
      line-height: 1.2;
      margin: 15px 0 2rem;
    }
    &-description {
    }
  }
}
</style>
